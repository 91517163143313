export class FetchError extends Error {
    constructor(message, status, json = null) {
        super(message);
        this.status = status;
        this.response = json;
    }
}

export function apiUrl( uri ) {
    return process.env.REACT_APP_API_SERVER + uri;
}

export function apiFetchJsonUri( uri ) {
    return fetch(apiUrl( uri ), {
        credentials: 'include'
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new FetchError("Error Processing Response From Server", response.status, response.json());
        }
    })
}

export function apiFetchDataUri( uri ) {
    return fetch(apiUrl( uri ), {
        credentials: 'include'
    }).then(response => {
        if (response.ok) {
            return response.text();
        } else {
            throw new FetchError("Error Processing Response From Server", response.status, response.json());
        }
    })
}

export function apiPutJsonUri( uri, data ) {
    return fetch(apiUrl( uri ), {
        credentials: 'include',
        method: 'put',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new FetchError("Invalid Response From Server", response.status, response.json());
        }
    })
}

export function apiDeleteUri(uri) {
    return fetch(apiUrl( uri ), {
        credentials: 'include',
        method: 'delete',

    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new FetchError("Invalid Response From Server", response.status, response.json());
        }
    })
}

export function apiFetchLoggedInUser() {
    return apiFetchJsonUri("/check-login");
}
export function apiLogoutUser() {
    return window.location.href=apiUrl("/api/logout");
}

export function apiFetchLibraries() {
    return apiFetchJsonUri("/api/libraries");
}

export function apiFetchAssignedCallSlips(userId) {
    return apiFetchJsonUri("/api/users/"+userId+"/callslips");
}

export function apiFetchAllCallSlips() {
    return apiFetchJsonUri("/api/callslips");
}

export function apiFetchOpenAndUnassignedCallSlips() {
    return apiFetchJsonUri("/api/callslips/unassigned");
}

export function apiFetchAllUsers() {
    return apiFetchJsonUri("/api/users");
}
export function apiFetchAssignableUsers() {
    return apiFetchJsonUri("/api/users/assignable");
}
export function apiCurrentCallSlipBatch( user ) {
    return apiFetchJsonUri("/api/users/"+user.id+"/call-slip-batches/current");
}
export function apiAssignUserCallSlips(user, callSlips) {
    return apiPutJsonUri("/api/users/" + user.id + "/call-slip-batches", callSlips);
}
export function apiSaveUser(user) {
    return apiPutJsonUri("/api/users", user);
}
export function apiDeleteUser(user) {
    return apiDeleteUri("/api/users/" + user.id);
}
export function apiSaveCallSlipBatch(callSlipBatch, updatedCallSlips) {
    return apiPutJsonUri("/api/call-slip-batches/" + callSlipBatch.id, updatedCallSlips);
}
export function apiFetchFillProblems() {
    return apiFetchJsonUri("/api/call-slip-fill-problems");
}

export function apiFetchBatchInfos() {
    return apiFetchJsonUri("/api/call-slip-batch-infos");
}

export function apiFetchCallSlipLocationList() {
    return apiFetchJsonUri("/api/call-slip-location-list");
}
export function apiFetchCallSlipStatusList() {
    return apiFetchJsonUri("/api/call-slip-status-list");
}
export function apiStoreCallSlipProgress(batchId, callSlipId, callSlipResponse) {
    return apiPutJsonUri("/api/call-slip-batches/"+batchId+"/call-slip/"+callSlipId+"/batch-response", callSlipResponse);
}

export function apiSaveCallSlipBatchAssigner(callSlipBatchId) {
    return apiFetchJsonUri('/api/call-slip-batches/'+callSlipBatchId+'/assigner-close');
}

export function apiFetchAllUsersWithBatches() {
    return apiFetchJsonUri("/api/users/active-batch");
}
export function apiFetchAvatarDataUrl(user) {
    return apiFetchDataUri("/api/users/" + user.id + "/avatar/data-url");
}
export function avatarImagePath(user) {
    return apiUrl("/api/users/" + user.id + "/avatar?" + user.version);
}
export function apiRelinkCallSlip(callSlipId, barcode) {
    let request = {
        callSlipId:callSlipId,
        barcode: barcode
    };
    return apiPutJsonUri("/api/call-slip/relink", request)
}

export function parseISOLocal(s) {
    let b = s.split(/\D/);
    return new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]);
}

