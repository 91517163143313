import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        minHeight: 50
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    dropdownuser:{
        padding: '5px'
    },
    dropdownuserwrapper: {
        marginTop: "5px"
    },
    dropdownuserText: {
        padding: "8px"
    },
    dropdownuserAvatar: {
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    ml1: {
        marginLeft: "10px"
    },
    warningIcon: {
        color: theme.palette.warning.main,
        height: "20px"
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    selectButton: {
        margin: "2px 0"
    },
    userButtons: {
        marginTop: "10px"
    },
    actionButton: {
        marginRight: "10px"
    },
    selectedCountText: {
        margin: "15px 0"
    },
    pt2: {
        marginTop: "2.5px"
    },
    item: {
        padding: "0 0 0 0",
        margin: "0 0 0 0"
    },
    refreshButton:{
        margin: "5px 8px 0 0",
        float: "right",
    },
    dialogTitle:{
        flexGrow: 1,
    },
    noWrap: {
        whiteSpace:"nowrap"
    }
}));
