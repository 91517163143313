import React, { useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import CancelIcon from "@material-ui/icons/Cancel";
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SelectDownIcon from '@material-ui/icons/SystemUpdateAlt';
import Grid from "@material-ui/core/Grid";
import WarningIcon from '@material-ui/icons/Warning';
import EnhancedTableToolbar from "./components/ui-components/EnhancedTableToolbar";
import {
  apiAssignUserCallSlips,
  apiFetchAssignableUsers,
  apiFetchOpenAndUnassignedCallSlips,
  apiFetchBatchInfos,
  avatarImagePath
} from "./functions/APIClient";
import UserBatch from "./components/assign-call-slip/UserBatch";
import {Tabs ,Tab} from "@material-ui/core";
import {extractUserDisplayName} from "./functions/UiFunctions";
import {TabPanel} from "./components/ui-components/TabPanel";
import RefreshIcon from '@material-ui/icons/Refresh';
import {assignSortedAndFilteredProto} from "./functions/SortFilterOrder";
import {useStyles} from "./styles/AssignCallSlipStyle";
import {messages} from "./toast-messages/AssignCallSlipMessages";
import {TextAndContent} from "./components/ui-components/TextAndContent";
import {EnhancedTableHead} from "./components/ui-components/EnhancedTableHeadAssign";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DialogButton} from "./components/ui-components/DialogButton";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";

const callNumberTableColumns = [
  { id: 'callNumberNormalized', numeric: false, disablePadding: false, label: 'Call Number' },
  { id: 'itemPermLocation', numeric: false, disablePadding: false, label: 'Item Location' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Call Slip Status' },
  { id: 'patronRequestDate', numeric: false, disablePadding: false, label: 'Request Time' }
];

const titleTableColumns = [
  { id: 'sortTitle', numeric: false, disablePadding: false, label: 'Title' },
  { id: 'itemPermLocation', numeric: false, disablePadding: false, label: 'Item Location' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Call Slip Status' },
  { id: 'patronRequestDate', numeric: false, disablePadding: false, label: 'Request Time' }
];

function MenuItemUser(props: { value: any, classes: any }) {
  return <MenuItem value={props.value} className={props.classes.dropdownuser}>
    <Grid container>
      <Grid>
        <Avatar alt={`${extractUserDisplayName(props.value)} Avatar`} src={avatarImagePath(props.value)}
                className={props.classes.dropdownuserAvatar}/>
      </Grid>
      <Grid>
        <Typography className={props.classes.dropdownuserText}>
          {extractUserDisplayName(props.value)}
        </Typography>
      </Grid>
    </Grid>
  </MenuItem>;
}

export function AssignCallSlip( {loggedInUser, toast}) {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('callNumberNormalized');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const dense = true;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectNum, setSelectNum] = React.useState(35);
  const [callSlips, setCallSlips] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [callSlipBatchInfos, setCallSlipBatchInfos] = React.useState([]);
  const [callSlipLocations, setCallSlipLocations] = React.useState(loggedInUser.libraries.map(l=>l.name));
  const [sortBy, setSortBy] = React.useState("callNumberNormalized");
  const [tableColumns, setTableColumns] = React.useState(callNumberTableColumns);

  useEffect(()=>{
    refreshCallSlipAndUserData(tab);
  }, [tab]);

  const refreshCallSlipAndUserData = (tab) => {
    if ( tab === 0 ) {
      apiFetchAssignableUsers().then((users) => setUsers(users));
      apiFetchOpenAndUnassignedCallSlips().then((callSlips) => setCallSlips(callSlips));
    } else {
      apiFetchBatchInfos().then((callSlipBatchInfos) => setCallSlipBatchInfos(callSlipBatchInfos));
    }
  };

  const refreshBatchesAndUsers = () => {
    refreshCallSlipAndUserData(tab);
  };

  const handleSelectNumDropDownClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const sortedAndFiltered = (useLocationFilter) =>{
   return assignSortedAndFilteredProto(callSlips, useLocationFilter, tableColumns, callSlipLocations, callNumberTableColumns,filters,order, orderBy)
  };

  const sortedAndFilteredCallslips = () => {
    return sortedAndFiltered( true);
  };

  const extractAllItemLocations = () => {
    let list = sortedAndFiltered( false);
    let r = new Set(list.map((cs)=>cs.itemPermLocation).filter((loc)=>loc));
    return [...r];
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectClick = ( num) => {

    if ( !selectedUser ) {
      return;
    }
      const newSelecteds = sortedAndFilteredCallslips().slice(0,num).map((n) => {
        return n.id});
      setSelected(newSelecteds);
  };

  const handleSelectFromClick = (event, fromRowId ) => {
    event.stopPropagation();
    if ( !selectedUser ) {
      toast(messages.selectWithoutUser);
      return;
    }
    if ( !selectedUser) return;

    let sortedCallSlips = sortedAndFilteredCallslips();
    let newSelecteds = [];
    let cnt = selectNum;
    let fnd = false;
    for ( let i=0;i<sortedCallSlips.length && cnt>0;i++) {
      if ( sortedCallSlips[i].id === fromRowId ) {
        fnd = true;
      }
      if ( fnd ) {
        newSelecteds.push( sortedCallSlips[i].id);
        cnt--;
      }
    }
    setSelected(newSelecteds);
  }

  const handleCallSlipClick = (event, id) => {
    if ( !selectedUser ) {
      toast(messages.selectWithoutUser);
      return;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const saveUserBatch = (event) => {
    let selectedCallSlips = callSlips.filter((callSlip)=>isSelected(callSlip.id));
    return apiAssignUserCallSlips(selectedUser, selectedCallSlips).then(()=> {
          setSelected([]);
          setPage(0);
          setSelectedUser(null);
          setCallSlipLocations(loggedInUser.libraries.map(l=>l.name));
          refreshBatchesAndUsers();
          toast(messages.saveAssignSuccess);
        }
    ).catch((error)=>{
      toast(messages.saveAssignFailure);
    });
  };

  const selectNone = (event) => {
    setSelected([])
    //setSelectedUser(null)
  };

  const handleSelectNumClose = (count) => {
    setSelectNum(count);
    setAnchorEl(null);
    //handleSelectClick(count)
  };

  const [filters, setFilters] = React.useState({
    problemFilter:"none",
    statusFilter: "New",
    locationFilter: "all",
    itemLocationFilter: ["all"],
    textFilter: null
  });

  const handleFilterSet = (key, value) => {
    const newFilters = {...filters, [key]:value};
    if ( key === "locationFilter" && value !== "all" ) {
      newFilters["itemLocationFilter"] = ["all"];
    }
    setPage(0);
    setFilters( newFilters);
  };

  const handleSortByChanged = (newSortBy) => {
    setSortBy(newSortBy);
    if (newSortBy === "title") {
      setTableColumns(titleTableColumns);
      setOrderBy("sortTitle");
    } else {
      setTableColumns(callNumberTableColumns);
      setOrderBy("callNumberNormalized")
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, callSlips.length - page * rowsPerPage);
  const numSelected=selected.length;

  const filteredUsers = users.filter((u)=>{
    const locationFilter = filters["locationFilter"];
    return locationFilter === "all" || u.libraries.some((l)=>l.name === locationFilter);
  });

  const callSlipLocationCounts = {};
  callSlips.forEach((callSlip)=>{
    if (callSlip.callSlipLocation && callSlip.status === "New") {
      let cnt = callSlipLocationCounts[callSlip.callSlipLocation] || 0;
      callSlipLocationCounts[callSlip.callSlipLocation] = ++cnt;
    }
  });

  return (
      <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs"
                >
                  <Tab label="Assign to Retrievers" />
                  <Tab label="Review Assignments"  />
                </Tabs>
              </Grid>
              <Grid style={{flexGrow:1}}>
                <Button startIcon={<RefreshIcon/>} onClick={refreshBatchesAndUsers} className={classes.refreshButton}>
                  Refresh
                </Button>
              </Grid>
            </Grid>
            <TabPanel value={tab} index={0}>
              <Grid container>
                <Grid>
                  <div className={classes.dropdownuserwrapper}>
                    <FormControl variant={"outlined"} className={classes.formControl}>
                      <Autocomplete
                          id="user-select"
                          value={(selectedUser && users.find((u)=>u.id === selectedUser.id)) || ""}
                          options={filteredUsers}
                          onChange={(event,value)=>{
                            setPage(0);
                            setSelected([]);
                            if(!value){
                              setSelectedUser(null);
                              setCallSlipLocations(loggedInUser.libraries.map(l=>l.name));
                              return;
                            }
                            setCallSlipLocations(value.libraries
                                .map((library)=>library.name)
                                .filter((location)=>loggedInUser.libraries.some((l)=>l.name === location)));
                            setSelectedUser(value);
                          }}
                          renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    variant="standard"
                                    label={<b className="cross-subheader"> Retriever </b>}
                                />
                            )
                          }}
                          renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                return <MenuItemUser id={`tag-user-${option.id}`} key={index} value={option} classes={classes}/>
                              })
                          }
                          getOptionLabel={(option) => option? extractUserDisplayName(option): ""}
                          renderOption={
                            (option) => {
                                return <MenuItemUser id={`option-user-${1}`} value={option} classes={classes}/>
                              }
                            }
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid className={classes.userButtons}>
                    <DialogButton
                        id={"assign-button"}
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        icon={<SaveIcon />}
                        disabled={selectedUser===null || selected.length === 0 }
                        onClick={saveUserBatch}
                        name={"Assign Callslips to Retriever"}
                    >
                    </DialogButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        id={"cancel-button"}
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        startIcon={<CancelIcon />}
                        onClick={selectNone}
                    >
                      Cancel
                    </Button>
                </Grid>
              </Grid>
              <EnhancedTableToolbar numSelected={selected.length}
                                    onFilterSet={handleFilterSet}
                                    showProblems={true}
                                    showNOSOptions={true}
                                    showSortBy={true}
                                    onSortByChanged={handleSortByChanged}
                                    sortBy={sortBy}
                                    showTextFilter={true}
                                    callSlipLocations={callSlipLocations}
                                    callSlipLocationCounts={callSlipLocationCounts}
                                    itemLocations={extractAllItemLocations()}
                                    filters={filters}/>
              <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                >
                  <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onSelectClick={handleSelectClick}
                      onRequestSort={handleRequestSort}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      handleSelectNumClose={handleSelectNumClose}
                      handleSelectNumDropDownClick={handleSelectNumDropDownClick}
                      tableColumns={tableColumns}
                      selectNum={selectNum}

                  >Select All</EnhancedTableHead>
                  <TableBody>
                    {sortedAndFilteredCallslips()
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                              <TableRow
                                  hover
                                  onClick={(event) => handleCallSlipClick(event, row.id)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.id}
                                  selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Grid container  style={{maxHeight:"10px",position:"relative",top:"-20px"}}>
                                    <Grid item xs={6} className={classes.pt2}  >
                                      <Checkbox
                                          checked={isItemSelected}
                                          id={`checkbox-${index+page*rowsPerPage}`}
                                          inputProps={{ 'aria-label': `checkbox-${labelId}`}}
                                      />
                                    </Grid>
                                    <Grid item xs={6} >
                                      <IconButton aria-label={"select next " + selectNum} title={"select next " + selectNum} color="primary" onClick={(e)=>handleSelectFromClick(e,row.id)}>
                                        <SelectDownIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell padding="checkbox">
                                </TableCell>
                                <TableCell align="left">
                                  {tableColumns === callNumberTableColumns && <>
                                  <TextAndContent tooltip={(row.callSlipProblem && row.callSlipProblem.problemType==="callnumber" && row.callSlipProblem.name) || ""}
                                      text={row.callNumber || "No Callnumber"} classes={classes} >
                                    {row.callSlipProblem && row.callSlipProblem.problemType==="callnumber" &&
                                    <WarningIcon className={classes.warningIcon} />}
                                    {row.voyagerCallSlipStatus === 3 && ` (Reassigned: ${row.callSlipLocation})`}
                                  </TextAndContent>
                                  </>}
                                  {tableColumns === titleTableColumns && <>
                                    <TextAndContent text={row.title} classes={classes}>
                                      {row.title}
                                    </TextAndContent>
                                  </>}
                                </TableCell>
                                <TableCell align="left">
                                  <TextAndContent text={row.itemPermLocation}
                                                  classes={classes}
                                                  tooltip={row.callSlipProblem && row.callSlipProblem.problemType==="location" && (row.callSlipProblem.name +
                                                      " (Holding: "+(row.holdingLocation || "none")+", Item: "+(row.itemPermLocation || "none")+")")}>
                                  {row.callSlipProblem && row.callSlipProblem.problemType==="location" &&
                                  <WarningIcon className={classes.warningIcon} />}
                                  </TextAndContent>
                                </TableCell>
                                <TableCell align="left">
                                  {row.status}
                                </TableCell>
                                <TableCell align="left">
                                  {row.patronRequestDate}
                                </TableCell>
                              </TableRow>
                          );
                        })}
                    {(!callSlips) && (
                        <TableRow>
                          <TableCell colSpan={6} style={{textAlign:"center", height: (dense ? 33 : 53) * emptyRows}}>
                            <h3>LOADING..</h3><CircularProgress />
                          </TableCell>
                        </TableRow>
                    )}
                    {callSlips.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={6} style={{textAlign:"center", height: (dense ? 33 : 53) * emptyRows}}>
                            <h3>No callslips available.</h3>
                          </TableCell>
                        </TableRow>
                    )}
                    {callSlips && callSlips.length > 0 && emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>

                          <TableCell colSpan={6} >
                          </TableCell>
                        </TableRow>
                    )}
                  </TableBody>
                </Table>

              </TableContainer>

              <Grid container>
                <Grid item lg>
                  <Typography className={classes.selectedCountText} color="inherit" component="div">
                    <Typography  className={classes.ml1}>{numSelected} selected</Typography>
                  </Typography>
                </Grid>
                <Grid item lg>
                  <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      count={sortedAndFilteredCallslips().length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel index={1} value={tab}>
              <UserBatch classes={classes} refreshUserWithBatches={refreshBatchesAndUsers} batchInfos={callSlipBatchInfos} toast={toast} loggedInUser={loggedInUser}/>
            </TabPanel>
          </Paper>
      </div>
  );
}