import Button from "@material-ui/core/Button";
import React from "react";

export const DialogButton = ({id, name, icon, label, onClick, color, className, disabled,setDisabledState=()=> {},variant ="contained",}) => {
    const [onClickDisable,setOnClickDisable] = React.useState(false);
    return (
        <Button
            aria-label={label}
            id={id || null}
            variant={variant}
            color={color || "primary"}
            size="medium"
            startIcon={icon}
            onClick={ async () =>{
                setOnClickDisable(true);
                setDisabledState(true);
                await onClick();
                setDisabledState(false);
                setOnClickDisable(false);
            }}

            className={className}
            style={{margin: "6px 8px"}}
            disabled={disabled || onClickDisable}
        >
            {name}
        </Button>
    );
};