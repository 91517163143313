export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const sortedAndFilteredCallslipsProto = (callSlips,order,orderBy, filters) => {
    if ( !callSlips ) return [];
    return stableSort(callSlips.filter((cs) => {
        return (
            (!filters.locationFilter || filters.locationFilter === "all" || cs.callSlipLocation === filters.locationFilter ) &&
            (!filters.itemLocationFilter ||
                filters.itemLocationFilter.includes("all") ||
                filters.itemLocationFilter.includes(cs.itemPermLocation)) &&
            ( !filters.statusFilter || filters.statusFilter === "all" || cs.status === filters.statusFilter ) &&
            ( !filters.problemFilter ||
                filters.problemFilter === "all" ||
                (filters.problemFilter === "none" && !cs.callSlipProblem) ||
                (filters.problemFilter === "only" && cs.callSlipProblem) ||
                (cs.callSlipProblem && cs.callSlipProblem.value === filters.problemFilter)
            )
        );
    }), getComparator(order, orderBy));
};

export const assignSortedAndFilteredProto = (callSlips, useLocationFilter, tableColumns, callSlipLocations, callNumberTableColumns,filters,order, orderBy) => {
    return stableSort(callSlips.filter((cs)=>{
        let text = (tableColumns===callNumberTableColumns?cs.callNumber:cs.title);
        if(text === null){
            text = '';
        }
        text = text.toLowerCase();
        return (
            (!filters.textFilter || (text.indexOf(filters.textFilter.toLowerCase())===0) || (text.substring(0, filters.textFilter.length)>filters.textFilter.toLowerCase())) &&
            (!filters.locationFilter || cs.callSlipLocation === filters.locationFilter ||
                (filters.locationFilter === "all" && (!callSlipLocations || callSlipLocations.some((l)=>l === cs.callSlipLocation ))))  &&
            (!useLocationFilter || (!filters.itemLocationFilter ||
                    filters.itemLocationFilter.includes("all") ||
                    filters.itemLocationFilter.includes(cs.itemPermLocation))
            ) &&
            ( !filters.statusFilter || filters.statusFilter === "all" || cs.status === filters.statusFilter ) &&
            ( !filters.problemFilter ||
                filters.problemFilter === "all" ||
                (filters.problemFilter === "none" && !cs.callSlipProblem) ||
                (filters.problemFilter === "only" && cs.callSlipProblem) ||
                ((cs.callSlipProblem && cs.callSlipProblem.value) === filters.problemFilter) )
        );
    }), getComparator(order, orderBy));
};

export const sortedAndFilteredBatchInfosProto = (batchInfos, order, orderBy, callSlipLocation, filter = null) => {
    return stableSort(batchInfos.filter(bi => {
        if (callSlipLocation === "All") {
            return true;
        } else {
            return bi.callSlipLocations.some(l => l === callSlipLocation)
        }
    }), getComparator(order,orderBy));
}