import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import React from "react";
import {DialogButton} from "./DialogButton";

export const ConfirmDialog = ({confirmTitle, open,handleClose , handleConfirm, classes,...props}) => {
    return(
        <Dialog open={open} classes={{ paper: classes.dialogPaper }} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{confirmTitle}</DialogTitle>
            <DialogContent>
                <List>
                    <ListItem>
                        {props.children}
                    </ListItem>
                    <ListItem>
                        <DialogButton name={"confirm"} icon={<DoneIcon />} label={"confirm"} onClick={handleConfirm} />
                        <DialogButton name={"cancel"} color="secondary" icon={<CancelIcon />} label={"Relink"} onClick={handleClose} />
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    )
};
