import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {DialogButton} from "../ui-components/DialogButton";
import {InfoButton} from "../ui-components/InfoButton";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";
import LinkIcon from "@material-ui/icons/Link";
import InfoIcon from "@material-ui/icons/Info";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import React from "react";
import {getBarcodes} from "../../functions/Barcode";
import WarningIcon from "@material-ui/icons/Warning";

export const CallSlip = ({ classes, callSlip, online, onUpdateCallSlipStatus, onRelink, onMoreInformation, onProblem, statusOptions, statusMap }) => {
    return(
        <Card key={callSlip.id}>
            <div style={{position:"relative", top:"-80px"}} ref={callSlip.ref} ></div>
            <CardContent >
                <TableContainer className={[classes[`card`], classes[callSlip.status.toLowerCase()]].join(" ")}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody >
                            <TableRow>
                                <TableCell align="right">
                                    <InfoButton className={classes.statusButton}>
                                        {statusOptions.map(o=><span key={statusMap[o]}>{o}: {statusMap[o]}<br /></span>)}
                                    </InfoButton>
                                    Call Slip Status:</TableCell>
                                <TableCell align="left">
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="position" name="position" value={callSlip.status}
                                                    onChange={(e) => onUpdateCallSlipStatus(callSlip, e.target.value)}>
                                            {statusOptions.map( (option) => {return  <FormControlLabel key={option} value={option}
                                                                                                       control={<Radio color="primary" />}
                                                                                                       label={option}/>
                                            })}
                                        </RadioGroup>
                                    </FormControl>

                                    <div className={classes.floatRight}>
                                        {callSlip.callSlipRelink && <Chip className={classes.chip} size="small" color={"primary"} label="Relinked" /> }
                                        {((callSlip.callSlipFillProblems && callSlip.callSlipFillProblems.length > 0) || (callSlip.notes))&&  <Chip className={classes.chip} size="small" color={"primary"} label="Note Recorded" /> }
                                        <DialogButton disabled={!online} name={"Relink"} color={"secondary"} icon={<LinkIcon />} label={"Relink"} onClick={onRelink} />
                                        <DialogButton name={"Details"} color={"secondary"} icon={<InfoIcon />} label={"Details"} onClick={onMoreInformation} />
                                        {callSlip.status === "FOS_2x" &&
                                            <DialogButton name={"Note"} color={"secondary"} icon={<NotificationImportantIcon />} label={"Note"} onClick={onProblem} />
                                        }
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">Call Number:</TableCell>
                                <TableCell align="left">{callSlip.callNumber}</TableCell>
                            </TableRow>
                            {(callSlip.voyagerCallSlipStatus === 3 && <TableRow>
                                <TableCell align="right">Reassigned:</TableCell>
                                <TableCell align="left">True <WarningIcon className={classes.warningIcon} /></TableCell>
                            </TableRow>) || null}
                            {(callSlip.enumeration &&
                                <TableRow>
                                    <TableCell align="right">Enum / Chron / Year:</TableCell>
                                    <TableCell align="left">{callSlip.enumeration || "none"}</TableCell>
                                </TableRow>) || null}
                            <TableRow>
                                <TableCell align="right">Title:</TableCell>
                                <TableCell align="left">{callSlip.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">Item Barcode:</TableCell>
                                <TableCell align="left">{getBarcodes(callSlip)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">Item Perm / Temp Location:</TableCell>
                                <TableCell align="left">{callSlip.itemPermLocation || "none"} / {callSlip.itemTempLocation ||"none"} </TableCell>
                            </TableRow>
                            {(callSlip.callSlipFillProblems && callSlip.callSlipFillProblems.length > 0 &&
                                <TableRow>
                                    <TableCell align="right">Problems:</TableCell>
                                    <TableCell align="left">{callSlip.callSlipFillProblems.map((p)=>p.name).join("; ")}</TableCell>
                                </TableRow>) || null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
};