export const messages = {
    "saveUserSuccess": {
        "message": "User saved successfully!",
        "severity": "success"
    },
    "saveUserFailure": {
        "message": "Error saving user!",
        "severity": "error"
    },
    "errorLoadingAvatar": {
        "message": "Error loading user picture",
        "severity": "error"
    },
    "deleteUserFailure": {
        "message": "Error deleting user!",
        "severity": "error"
    },
    "deleteUserSuccess": {
        "message": "User deleted successfully!",
        "severity": "success"
    }
};
