import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {DialogButton} from "../ui-components/DialogButton";
import {InfoButton} from "../ui-components/InfoButton";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import LinkIcon from "@material-ui/icons/Link";
import ArrowBack from "@material-ui/icons/ArrowBack";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import WarningIcon from "@material-ui/icons/Warning";
import PriorIcon from "@material-ui/icons/NavigateBefore";
import NextIcon from "@material-ui/icons/NavigateNext";
import React from "react";
import {KeyValue} from "../ui-components/KeyValue";
import {getBarcodes} from "../../functions/Barcode";


export const SingleViewCallSlip = ({ classes, callSlip, online, setCallSlipIndex, callSlipIndex, callSlips,
                                       onUpdateCallSlipStatus, onRelink, onProblem, callSlipFillProblems, statusOptions, statusMap ,setTab}) => {
    return(
        <Card >
            <CardContent className={[classes.card, classes[callSlip.status.toLowerCase()]].join(" ")}>
                <Typography className={classes.heading} component={'div'} >
                    <div className={[classes.card, classes[callSlip.status.toLowerCase()]]}>
                        <KeyValue singleLine={true}
                                  name={
                                      <>
                                          <InfoButton className={classes.statusButton}>
                                              {statusOptions.map(o=><span key={statusMap[o]}>{o}: {statusMap[o]}<br /></span>)}
                                          </InfoButton>
                                          CallSlip Status
                                      </>
                                  }
                                  value={
                                      <Grid container>
                                          <Grid item className={classes.radioGrid}>
                                              <RadioGroup className={classes.radioGroup} row aria-label="position" name="position" value={callSlip.status}
                                                          onChange={(e) => onUpdateCallSlipStatus(callSlip, e.target.value)}>
                                                  {statusOptions.map( (option) => {return  <FormControlLabel key={option} value={option}
                                                                                                             control={<Radio color="primary" />}
                                                                                                             label={option}/>
                                                  })}
                                              </RadioGroup>
                                          </Grid>
                                          <Grid item>
                                              <DialogButton name={"Relink"} disabled={!online} color={"secondary"} icon={<LinkIcon />} label={"Relink"} onClick={onRelink} />
                                              <DialogButton name={"Back to List"} color={"secondary"} icon={<ArrowBack />} label={"Back to List"} onClick={()=>(setTab(0))} />
                                              {callSlip.incomingStatus === "NOS" &&
                                                  <DialogButton name={"Note"} color={"secondary"}
                                                                icon={<NotificationImportantIcon/>} label={"Note"}
                                                                onClick={onProblem}/>
                                              }
                                          </Grid>
                                      </Grid>
                                  }
                        />
                        {callSlip.lastDischargeDateTime && <KeyValue name={"Last Discharged"} value={callSlip.lastDischargeDateTime.replace("T", " ")} singleLine={true} />}
                        <KeyValue name={"Item Location"} value={(callSlip.itemPermLocation || "none")}  singleLine={true}/>
                        <KeyValue name={"Call Number"} value={callSlip.callNumber} singleLine={true} />
                        {callSlip.voyagerCallSlipStatus === 3 && <KeyValue name={"Reassigned"} value={<Typography>True <WarningIcon className={classes.warningIcon} /></Typography>} singleLine={true} />}
                        {callSlip.enumeration && <KeyValue name={"Enum / Chron / Year"} value={callSlip.enumeration || "none"} singleLine={true} />}
                        <KeyValue name={"Item Barcode"} value={getBarcodes(callSlip)}  singleLine={true}/>
                        <KeyValue name={"Title"} value={callSlip.title} singleLine={true} />
                        <KeyValue name={"Author"} value={callSlip.author || "none"}  singleLine={true}/>
                        <KeyValue name={"Holding / Temp Location"} value={(callSlip.holdingLocation || "none") + " / " + (callSlip.itemTempLocation ||"none")}  singleLine={true}/>
                        <KeyValue name={"Route To"} value={callSlip.pickupLocationDisplay}  singleLine={true}/>
                        <KeyValue name={"Patron Name / Barcode"} value={`${callSlip.patronFirstName || ''} ${callSlip.patronLastName || "" } / ${callSlip.patronBarcode || ""}`} singleLine={true} />
                        <KeyValue name={"Patron Request Date"} value={callSlip.patronRequestDate} singleLine={true} />
                        {callSlip.patronComment && <KeyValue name={"Patron Comment"} value={callSlip.patronComment}  singleLine={true}/>}
                        {callSlip.callSlipFillProblems && callSlip.callSlipFillProblems.length > 0 && <KeyValue name={"Problems"} value={callSlip.callSlipFillProblems.map((p)=>p.name).join("; ")}  singleLine={true}/>}
                    </div>
                    <div>
                        <Grid container direction={"row-reverse"}>
                            <Grid >
                                <DialogButton name={"Previous"} icon={<PriorIcon />} label={"Previous"}
                                              onClick={()=>setCallSlipIndex(Math.max(0, callSlipIndex - 1))} />
                                {callSlipIndex + 1} of {callSlips.length}
                                <DialogButton name={"Next"} icon={<NextIcon />} label={"Next"}
                                              onClick={()=>setCallSlipIndex(Math.min(callSlips.length - 1, callSlipIndex + 1))} />
                            </Grid>
                        </Grid>
                    </div>
                </Typography>
            </CardContent>
        </Card>
    )
};