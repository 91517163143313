import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import PropTypes from "prop-types";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    toolbar: {
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.info.dark,
                backgroundColor: lighten(theme.palette.primary.dark, 0.85),
            }
            : {
                color: theme.palette.text.primary.dark,
                backgroundColor: theme.palette.success.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    dropdownuser:{
        padding: '5px'
    },
}));

export default function EnhancedTableToolbar(props) {
    const classes = useToolbarStyles();
    const { showTextFilter, onFilterSet, filters, showSortBy, onSortByChanged, sortBy, showNOSOptions, showProblems,
        callSlipLocations, itemLocations, disableLocationsAlls, callSlipLocationCounts, } = props;

    const handleFilterSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ( event.target.name && onFilterSet ) {
            onFilterSet( event.target.name, event.target.value);
        }
    };

    const handleFilterMultipleSelectChange = (name, newValue) => {
        if ( name && onFilterSet ) {
            let valueToPass = newValue;
            if(valueToPass && (valueToPass.length > 0) ) {
                if (valueToPass[0] === 'all' && valueToPass.length > 1) {
                   valueToPass.shift();
                }
                else if (valueToPass.indexOf('all') > 0 ) {
                    valueToPass = ['all'];
                }
            }
            else  valueToPass = ['all'];


            onFilterSet(name, valueToPass);
        }
    };

    const handleSortBySelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ( event.target.name && onSortByChanged ) {
            onSortByChanged( event.target.value);
        }
    };


    return (
        <Toolbar>
            <Grid container spacing={3} className={classes.toolbar}>
                <Grid item md={2}>
                    <div className={"filter-item"}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="call-slip-location-select-label"><b className="cross-subheader"> CallSlip Print Group </b></InputLabel>
                            <Select
                                labelId="call-slip-location-select-label"
                                id="call-slip-location-select"
                                name={"locationFilter"}
                                onChange={handleFilterSelectChange}
                                value={filters.locationFilter || ""}
                            >{!disableLocationsAlls && <MenuItem value='all'>All</MenuItem>}
                                {callSlipLocations && callSlipLocations.map( (location,index)=><MenuItem key={index} value={location}>{location}{callSlipLocationCounts && ` (${callSlipLocationCounts[location] || '0'})`}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                {itemLocations &&
                <Grid item md={2}>
                    <div className={"filter-item"}>
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                id="item-location-select"
                                options={['all',itemLocations].flat()}
                                name={"itemLocationFilter"}
                                onChange={(event, newValue) =>
                                    handleFilterMultipleSelectChange("itemLocationFilter",newValue)
                                }
                                value={[filters.itemLocationFilter].flat() || ["all"]}
                                multiple
                                disableClearable={true}
                                getOptionLabel={
                                    (option) => {
                                        return `${option}`;
                                    }
                                }
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        if (option.includes('all')) {
                                           return  "All"
                                        }
                                        else
                                           return <Chip size={"small"} variant={"outlined"} color={"primary"} label={option} {...getTagProps({index})} />
                                    })

                                }
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                            label={<b className="cross-subheader"> Item Locations </b>}
                                            placeholder={filters.itemLocationFilter.length===0?"All":""}
                                        />
                                    )
                                }}
                            />
                        </FormControl>
                    </div>
                </Grid>}
                <Grid item md={2}>
                    <div className={"filter-item"}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="status-filter-select-label"><b className="cross-subheader"> Call Slip Status </b></InputLabel>
                            <Select
                                labelId="status-filter-select-label"
                                id="status-filter-selec"
                                name={"statusFilter"}
                                value={filters.statusFilter || ""}
                                onChange={handleFilterSelectChange}
                            >
                                <MenuItem value='all'>All</MenuItem>
                                <MenuItem value='New'>New</MenuItem>
                                {showNOSOptions && <MenuItem value='NOS'>NOS</MenuItem>}
                                {!showNOSOptions && <MenuItem value='FOS'>FOS</MenuItem>}
                                {!showNOSOptions && <MenuItem value='NOS'>NOS</MenuItem>}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                {showSortBy &&
                <Grid item md={2}>
                    <div className={"filter-item"}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="list-by-select-label"><b className="cross-subheader"> List By </b></InputLabel>
                            <Select
                                labelId="list-by-select-label"
                                id="list-by-select"
                                onChange={handleSortBySelectChange}
                                name={"list-by"}
                                value={sortBy}
                            >
                                <MenuItem value='callNumberNormalized'>Call Number</MenuItem>
                                <MenuItem value='title'>Title</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Grid>}
                {showTextFilter &&
                <Grid item md={2}>
                    <div className={"filter-item"}>
                        <FormControl className={classes.formControl}>
                            <InputLabel  id={"text-filter-label"}shrink><b className="cross-subheader">Find in Call No/Title</b></InputLabel>
                            <Input onChange={handleFilterSelectChange} inputProps={{'aria-labelledby': "text-filter-label"}} id={"text-filter"} name={"textFilter"} value={filters.textFilter || ""}/>
                        </FormControl>
                    </div>
                </Grid>
                }
                {showProblems &&
                <Grid item md={2}>
                    <div className={"filter-item"}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="filter-item-select-label"><b className="cross-subheader"> Problem Category </b></InputLabel>
                            <Select
                                labelId="filter-item-select-label"
                                id="filter-item-select"
                                onChange={handleFilterSelectChange}
                                name={"problemFilter"}
                                value={filters.problemFilter}
                            >
                                <ListSubheader> General </ListSubheader>
                                <MenuItem value='none'>No Problems</MenuItem>
                                <MenuItem value='only'>Only Problems</MenuItem>
                                <MenuItem value='all'>All Call Slips</MenuItem>
                                <ListSubheader> Specific Problems </ListSubheader>
                                <MenuItem value='cns'>Call Number Suppressed</MenuItem>
                                <MenuItem value='cno'>Call Number On Order</MenuItem>
                                <MenuItem value='cni'>Call Number In Process</MenuItem>
                                <MenuItem value='cnu'>Call Number Uncat</MenuItem>
                                <MenuItem value='cnn'>Call Number Null</MenuItem>
                                <MenuItem value='cnp'>Call Number smlpres</MenuItem>
                                <MenuItem value='hli'>Holding Loc is not Item Perm Loc</MenuItem>
                                <MenuItem value='hly'>Holding Loc is sml Yale class call number</MenuItem>
                                <MenuItem value='yta'>NonCirculating Temp Location</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Grid>}
            </Grid>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
