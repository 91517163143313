export const messages ={
    "problemOnNew": {
        "message": "Cannot report problems while Call Slip is \"New\"!",
        "severity": "error"
    },
    "saveBatchError": {
        "message": "There was an error while submitting your callslips!",
        "severity": "error",
    },
    "relinkSuccessful": {
        "message": "Relinking was successful.",
        "severity": "info"
    },
    "relinkUnsuccessful": {
        "message": "Relinking was unsuccessful!",
        "severity": "error"
    },"relinkUnable": {
        "message": "Unable to relink callslip!",
        "severity": "error"
    },"problemsReported": {
        "message": "Problems have been saved.",
        "severity": "info"
    },

};