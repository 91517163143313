import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";

export function EnhancedTableHead({ classes, order, orderBy, onRequestSort, handleSelectNumClose, tableColumns, selectNum}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Grid container>
                        <Grid item xs={6}><Typography className={classes.selectButton}>#</Typography></Grid>
                        <Grid item xs={6}><Select name={"selectCount"}
                                                  labelId="select-count-label"
                                                  onChange={(e)=>handleSelectNumClose(e.target.value)}
                                                  value={selectNum}
                        >
                            <MenuItem value='10'>10</MenuItem>
                            <MenuItem value='20'>20</MenuItem>
                            <MenuItem value='35'>35</MenuItem>
                        </Select></Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                </TableCell>
                {tableColumns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}