import React, {useEffect, useState} from 'react';
import {
    apiSaveCallSlipBatchAssigner, apiCurrentCallSlipBatch, parseISOLocal,
} from "../../functions/APIClient";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import WarningIcon from "@material-ui/icons/Warning";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ConfirmDialog} from "../ui-components/ConfirmDialog";
import {EnhancedTableHead} from "../ui-components/EnhancedTableHead";
import {sortedAndFilteredCallslipsProto,sortedAndFilteredBatchInfosProto} from "../../functions/SortFilterOrder";


function TextAndContent(props) {
    return <div className={props.classes.flexCenter} title={props.tooltip}>
        {props.text}
        {props.children}
    </div>
};

const headCellsBatch = [
    {id: 'callNumber', numeric: false, disablePadding: false, label: 'Call Number', sortable: true},
    {id: 'callSlipLocation', numeric: false, disablePadding: false, label: 'Location', sortable: true},
    {id: 'status', numeric: false, disablePadding: false, label: 'Call Slip Status', sortable: true},
    {id: 'callSlipFillProblems', numeric: false, disablePadding: false, label: 'Problems', sortable: false},
];
const headCellsBatchInfosCallNumber = [
    {id: 'actions', numeric: false, disablePadding: false, label: 'Actions', sortable: false},
    {id: 'userSort', numeric: false, disablePadding: false, label: 'Retriever', sortable: true},
    {id: 'callSlipLocations', numeric: false, disablePadding: false, label: 'CallSlip Group (total)', sortable: true},
    {id: 'minCallNumber', numeric: false, disablePadding: false, label: 'Beginning Call Number', sortable: true},
    {id: 'maxCallNumber', numeric: false, disablePadding: false, label: 'Ending Call Number', sortable: true},
    {id: 'startDateTime', numeric: false, disablePadding: false, label: 'Start Time', sortable: true},
    {id: 'mostRecentResponse', numeric: false, disablePadding: false, label: 'Last Response', sortable: true},
    {id: 'remaining', numeric: false, disablePadding: false, label: 'Remaining', sortable: false},
];
const headCellsBatchInfosTitle = [
    {id: 'actions', numeric: false, disablePadding: false, label: 'Actions', sortable: false},
    {id: 'userSort', numeric: false, disablePadding: false, label: 'Retriever', sortable: true},
    {id: 'callSlipLocations', numeric: false, disablePadding: false, label: 'CallSlip Group (total)', sortable: true},
    {id: 'minSortTitle', numeric: false, disablePadding: false, label: 'Beginning Title', sortable: true},
    {id: 'maxSortTitle', numeric: false, disablePadding: false, label: 'Ending Title', sortable: true},
    {id: 'startDateTime', numeric: false, disablePadding: false, label: 'Start Time', sortable: true},
    {id: 'mostRecentResponse', numeric: false, disablePadding: false, label: 'Last Response', sortable: true},
    {id: 'remaining', numeric: false, disablePadding: false, label: 'Remaining', sortable: false},
];


const BatchTable = ({classes, callSlips, open, user, handleClose}) => {

    const [order, setOrder] = useState('avatar');
    const [orderBy, setOrderBy] = useState('avatar');
    const [page, setPage] = useState(0);
    const dense = true;
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const filters = {
        problemFilter: "all",
        statusFilter: "all",
        locationFilter: "all"
    };

    const sortedAndFilteredCallslips = () => {
        return sortedAndFilteredCallslipsProto(callSlips, order, orderBy,filters);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (

        <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle  id="form-dialog-title">
                <Grid container>
                    <Grid className={classes.dialogTitle}>
                        CallSlips assigned to retriever {user}
                    </Grid>
                    <Grid>
                        <Button color={"primary"} onClick={handleClose} startIcon={<CloseIcon/>} variant={"contained"} >Close</Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            headCells={headCellsBatch}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                        >Select All</EnhancedTableHead>
                        <TableBody>
                            {sortedAndFilteredCallslips()
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell align="left">
                                                <TextAndContent text={row.callNumber || "No Callnumber"} classes={classes}
                                                                tooltip={row.callSlipProblem && row.callSlipProblem.problemType === "callnumber" && row.callSlipProblem.name}>{row.callSlipProblem && row.callSlipProblem.problemType === "callnumber" &&
                                                <WarningIcon className={classes.warningIcon}/>}</TextAndContent>
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextAndContent text={row.callSlipLocation} classes={classes}
                                                                tooltip={row.callSlipProblem && row.callSlipProblem.problemType === "location" && row.callSlipProblem.name}>{row.callSlipProblem && row.callSlipProblem.problemType === "location" &&
                                                <WarningIcon className={classes.warningIcon}/>}</TextAndContent>
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.status}{row.incomingStatus === 'NOS' && row.status === 'New' && ' (2x)'}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.callSlipFillProblems.map((p)=>p.name).join(", ")}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={sortedAndFilteredCallslips().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </DialogContent>
        </Dialog>
    )
};

export const UserBatch = ({classes, refreshUserWithBatches, batchInfos, loggedInUser}) => {
    const [callSlips, setCallSlips] = useState([]);
    const [selectedBatchInfo, setSelectedBatchInfo] = useState({});
    const [order, setOrder] = useState('avatar');
    const [orderBy, setOrderBy] = useState('avatar');
    const [page, setPage] = useState(0);
    const dense = true;
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [displayColumn, setDisplayColumn] = useState("callNumber");
    const [batchToSubmit, setBatchToSubmit] = useState(null);
    const [callSlipLocation, setCallSlipLocation] = useState("All");

    useEffect(() => {
        selectedBatchInfo && selectedBatchInfo.userId && apiCurrentCallSlipBatch({id: selectedBatchInfo.userId}).then((callSlipBatch) => {
            setCallSlips( callSlipBatch.callSlips.map((cs)=>{return cs;}) );
        }).catch(() => {
        })
    }, [selectedBatchInfo]);

    const sortedAndFilteredBatchInfos = () => {
        return sortedAndFilteredBatchInfosProto(batchInfos, order, orderBy, callSlipLocation)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };


    const handleSubmitBatch = (event,batchInfo) => {
        setBatchToSubmit(batchInfo);
    };

    const handleSubmitBatchCancel = (event) => {
        setBatchToSubmit(null);
    };

    const handleSubmitBatchConfirm = (event) => {
        apiSaveCallSlipBatchAssigner(batchToSubmit.callSlipBatchId).then(savedBatch => {
            refreshUserWithBatches();
            setBatchToSubmit(null);
        }).catch(e =>
            console.log("Error:", e)
        );
    };

    const handleDisplayColumnChange = (event) => {
        setDisplayColumn(event.target.value);
        if ( event.target.value === "title" ) {
            setOrderBy("minSortTitle");
        } else {
            setOrderBy("minCallNumber")
        }
     };
    const handleCallSlipLocation = (event) =>{
        setCallSlipLocation(event.target.value);
        }
        // , setCallSlipLocation] = useState("All");

    return (
        <div>
            <ConfirmDialog
                open={batchToSubmit || false}
                handleClose={handleSubmitBatchCancel}
                handleConfirm={handleSubmitBatchConfirm}
                classes={classes}
                confirmTitle={batchToSubmit && (batchToSubmit.cancellable ? "Cancel Batch?" : "Submit Batch for Retriever?")}
            >
                {batchToSubmit && (batchToSubmit.cancellable ?
                    <Typography>Are you sure you want to cancel this batch?</Typography>
                    :
                    <Typography>The retriever has already started working on this batch. Are you sure you want to submit
                        their responses and close the batch?</Typography>
                    )
                }
            </ConfirmDialog>
            <BatchTable classes={classes}
                        handleClose={handleCloseDialog}
                        open={openDialog}
                        callSlips={callSlips}
                        user={[selectedBatchInfo.userLastName ,selectedBatchInfo.userFirstName].join(", ")}
            />
            {batchInfos && batchInfos.length > 0 &&
                <div>

                    <FormControl className={classes.formControl}>
                        <InputLabel id="select-display-column-lbl"><b className="cross-subheader"> List By </b></InputLabel>
                        <Select
                            labelId="select-display-column-lbl"
                            onChange={handleDisplayColumnChange}
                            value={displayColumn}
                        >
                            <MenuItem value='callNumber'>Call Number</MenuItem>
                            <MenuItem value='title'>Title</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="callslip-group-column-lbl"><b className="cross-subheader"> CallSlip Group </b></InputLabel>
                        <Select
                            labelId="callslip-group-column-lbl"
                            value={callSlipLocation}
                            onChange={handleCallSlipLocation}
                        ><MenuItem value="All"> All </MenuItem>
                            {loggedInUser.libraries.map((library)=> <MenuItem value={library.name}>{library.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                headCells={displayColumn === "title" ? headCellsBatchInfosTitle : headCellsBatchInfosCallNumber}
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                            >Select All</EnhancedTableHead>
                            <TableBody>
                                {sortedAndFilteredBatchInfos()
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row,index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align="left" className={classes.noWrap}>
                                                    <Button className={classes.actionButton} color={"primary"}
                                                            variant={"outlined"} onClick={() => {
                                                        setOpenDialog(true);
                                                        setSelectedBatchInfo(row)
                                                    }}>View</Button>
                                                    <Button className={classes.actionButton} color={"primary"}
                                                            variant={"outlined"} onClick={(event) => {
                                                        handleSubmitBatch(event, row)
                                                    }}>{row.cancellable ? "Cancel Batch" : "Submit Batch"}</Button>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {`${row.userLastName}, ${row.userFirstName}`}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.callSlipLocations.join(", ")}
                                                    &nbsp;({row.callSlipCount})
                                                </TableCell>
                                                <TableCell align="left">
                                                    {displayColumn==="title"?row.minTitle:row.minCallNumber}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {displayColumn==="title"?row.maxTitle:row.maxCallNumber}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.startDateTime && parseISOLocal(row.startDateTime).toLocaleString()}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.mostRecentResponse && parseISOLocal(row.mostRecentResponse).toLocaleString()}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {(row.callSlipCount - row.responseCount) + " of " + row.callSlipCount}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={sortedAndFilteredBatchInfos().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                </div>
            }
            {(!batchInfos || batchInfos.length === 0) &&
                <Typography>No assigned Callslip Batches</Typography>
            }
        </div>

    )
};

export default UserBatch

