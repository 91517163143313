import React, { useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import {CardContent, Tab, Tabs, TextField} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import Checkbox from "@material-ui/core/Checkbox";
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import Grid from "@material-ui/core/Grid";
import EnhancedTableToolbar from "./components/ui-components/EnhancedTableToolbar";
import CircularProgress from '@material-ui/core/CircularProgress';
import localForage from 'localforage';
import {ConfirmDialog} from './components/ui-components/ConfirmDialog'
import {WorkProgress} from './components/ui-components/Progress'
import {DialogButton} from './components/ui-components/DialogButton'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {
    apiCurrentCallSlipBatch,
    apiFetchCallSlipStatusList,
    apiFetchFillProblems,
    apiLogoutUser,
    apiRelinkCallSlip,
    apiSaveCallSlipBatch,
    apiStoreCallSlipProgress, parseISOLocal
} from "./functions/APIClient";
import {sortedAndFilteredCallslipsProto} from "./functions/SortFilterOrder";
import {SingleViewCallSlip} from "./components/my-call-slip/SingleViewCallSlip";
import {CallSlip} from "./components/my-call-slip/CallSlip";
import {KeyValue} from "./components/ui-components/KeyValue";
import {useStyles} from "./styles/MyCallSlipStyle";
import {messages} from "./toast-messages/MyCallSlipMessages";

export function MyCallSlip({loggedInUser, online, toast})  {

    const classes = useStyles();
    const [callSlips, setCallSlips] = useState(null);
    const [callSlipBatch, setCallSlipBatch] = useState( []);
    const [relinkCallSlip, setRelinkCallSlip] = useState(null);
    const [moreInfoCallSlip, setMoreInfoCallSlip] = useState(null);
    const [problemCallSlip, setProblemCallSlip] = useState(null);
    const [relinkBarcode, setRelinkBarcode] = useState("");
    const [currentTab, setCurrentTab] = useState(0);
    const [callSlipIndex, setCallSlipIndex] = useState(0);
    const [callSlipsSubmitted, setCallSlipsSubmitted] = useState(false);
    const order = 'asc';
    const [orderBy, setOrderBy] = React.useState('callNumberNormalized');
    const [startTime, setStartTime] = React.useState(new Date());
    const [endTime, setEndTime] = React.useState(null);
    const [itemIssues, setItemIssues] = React.useState([]);
    const [itemNotes, setItemNotes] = React.useState("");
    const [callSlipLoadError, setCallSlipLoadError] = React.useState(null);
    const [callSlipFillProblems, setCallSlipFillProblems] = React.useState([]);
    const [showSubmitConfirmDialog, setShowSubmitConfirmDialog] = React.useState(false);
    const [callSlipLocations, setCallSlipLocations] = React.useState([]);
    const [reportProblemSaveDisable, setReportProblemSaveDisable] = React.useState(false);
    const [firstSearchStatusOptions, setFirstSearchStatusOptions] = React.useState([]);
    const [secondSearchStatusOptions, setSecondSearchStatusOptions] = React.useState([]);
    const [reloadCacheConfirmOpen, setReloadCacheConfirmOpen] = React.useState(false);
    const [relinking, setRelinking] = React.useState(false);
    const [relinkError, setRelinkError] = React.useState(null);
    const [statusMap, setStatusMap] = React.useState({});
    const [submitDisable, setSubmitDisable] = React.useState(false);
    const cachingFieldList = ["status", "callSlipFillProblems"];

    const storeProgress = ( callSlipBatch, callSlip ) => {
        const progress = {
            status: callSlip.status,
            callSlipFillProblemList: callSlip.callSlipFillProblems,
            notes: callSlip.notes
        }
        apiStoreCallSlipProgress(callSlipBatch.id, callSlip.id, progress).then(
            ()=>console.log("Response saved")
        ).catch(
            ()=>console.log("Unable to save")
        );
    };

    const restoreCache = (callSlipBatch, callSlips)=> {
        localForage.getItem("batchCache").then((cache)=>{
            if ( cache && cache.id === callSlipBatch.id ) {
                const cacheMap = {};
                cache.callSlips.forEach((callSlip)=>cacheMap[callSlip.id] = callSlip);
                const newCallSlips = callSlips.map((cs)=>{
                    const cachedRecord = cacheMap[cs.id];
                    if ( cachedRecord ) {
                        cs = {...cs}; // copy
                        cachingFieldList.forEach((field) =>
                            cs[field] = cachedRecord[field]
                        );
                        storeProgress( callSlipBatch, cs);
                    }
                    return cs;
                });
                setCallSlips(newCallSlips);
            }
        })
    }

    // Checks if the cache will alter the values.  If yes, display confirmation dialog.
    const checkCache = (callSlipBatch, callSlips)=> {
        localForage.getItem("batchCache").then((cache)=>{
            if ( cache && cache.id === callSlipBatch.id ) {
                const cacheMap = {};
                cache.callSlips.forEach((callSlip)=>cacheMap[callSlip.id] = callSlip);
                const someChanged = callSlips.some((cs)=>{
                    const cachedRecord = cacheMap[cs.id];
                    if ( cachedRecord ) {
                        if (cs["status"] !== cachedRecord["status"]) return true;
                        console.log(cs);console.log(cachedRecord);
                        if ((cs.callSlipFillProblems === null || cs.callSlipFillProblems.length === 0) !==
                            (cachedRecord.callSlipFillProblems === null || cachedRecord.callSlipFillProblems.length === 0) )
                            return true;
                        if ( cs.callSlipFillProblems !== null ) {
                            if ( cs.callSlipFillProblems.length !== cachedRecord.callSlipFillProblems.length )
                                return true;
                            for ( let i=0;i<cs.callSlipFillProblems.length;i++ ) {
                                if ( cs.callSlipFillProblems[i].id !== cachedRecord.callSlipFillProblems[i].id )
                                    return true;
                            }
                        }
                    }
                    return false;
                });
                setReloadCacheConfirmOpen(someChanged);
            }
        })
    }

    const storeCache = (callSlips)=> {
        const cache = {
            id: callSlipBatch.id,
            callSlips: callSlips.map((callSlip)=> {
                const r = {id: callSlip.id};
                cachingFieldList.forEach((field) =>
                    r[field] = callSlip[field]
                );
                return r;
            })};
        localForage.setItem("batchCache", cache);
    };

    useEffect(()=> {
        apiFetchCallSlipStatusList().then((list)=>{
            let newStatusMap = {}
            list.forEach( (status)=> {
                newStatusMap[status.value] = status.description;
            });
            setStatusMap(newStatusMap);
            setFirstSearchStatusOptions(list.filter((status)=>[0, 1].includes(status.searchIndex))
                .map((status)=>status.value));
            setSecondSearchStatusOptions(list.filter((status)=>[0,2].includes(status.searchIndex))
                .map((status)=>status.value));
        })
    },[]);



    useEffect(() => {
        apiCurrentCallSlipBatch(loggedInUser).then((callSlipBatch) => {
            setCallSlipBatch( callSlipBatch );
            setCallSlips( callSlipBatch.callSlips.map((callSlip) =>{
                callSlip.ref = React.createRef()
                return callSlip
            }) );
            setCallSlipLocations([...(new Set(callSlipBatch.callSlips.map((cs)=>cs.callSlipLocation)))]);
            // check that cache exists and will change values
            checkCache(callSlipBatch, callSlipBatch.callSlips )
        }).catch(() => {
            setCallSlips([]);
            setCallSlipLoadError("No CallSlips Available")
        })
    }, [loggedInUser]);

    useEffect( () => {
        const fetchFillProblems = () => {
            apiFetchFillProblems().then((problems) => {
                if (!problems  || problems.length === 0) {
                } else {
                    setCallSlipFillProblems(problems);
                }
            });
        };
        fetchFillProblems();
    },[])

    const handleScrollToItem = callSlip => {
        if (callSlip.ref.current) callSlip.ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    const handleStatusChange = (callSlip, status) => {
        const newCallSlips = callSlips.map((cs)=> {
            if ( cs === callSlip ) {
                // clear callSlipFillProblems on status change
                cs = {...cs, status: status, callSlipFillProblems: []};
                callSlip = cs;
                storeProgress(callSlipBatch, cs);
            }
            return cs;
        });
        setCallSlips([...newCallSlips]);
        storeCache(newCallSlips);
    };

    const handleIssuesChange = (callSlip, issues, itemNotes) => {
        const newCallSlips = callSlips.map((cs)=> {
            if ( cs === callSlip ) {
                cs = {...cs, callSlipFillProblems: issues, notes: itemNotes};
                storeProgress(callSlipBatch, cs);
            }
            return cs;
        });
        setCallSlips(newCallSlips);
        storeCache(newCallSlips);
    };

    const handleRelinkBarcodeClose = () => {
        setRelinkCallSlip(null);
        setRelinkBarcode("");
        setRelinkError(null);
    };

    const handleSaveRelinkClose = () => {
        setRelinkError(null);
        setRelinking(true);
        apiRelinkCallSlip(relinkCallSlip.id, relinkBarcode).then((response) => {
            if ( response.success ) {
                let newCallSlips = callSlips.map((cs) => {
                    if (cs.id === relinkCallSlip.id) {
                        let newCs = response.callSlip;
                        newCs.status = cs.status;
                        newCs.callSlipFillProblems = cs.callSlipFillProblems;
                        cs = newCs;
                    }
                    return cs;
                });
                toast(messages.relinkSuccessful);
                setCallSlips(newCallSlips);
                storeCache(newCallSlips);
                setRelinkCallSlip(null);
            } else {
                toast(messages.relinkUnsuccessful)
                setRelinkError("Unsuccessful relink: " + response.errorMessage);
                console.log("ERROR (Unsuccessful) SAVING RELINK");
            }
        }).catch((e)=> {
            toast(messages.relinkUnable)
            setRelinkError("Unable to relink: " + e);
            console.log("ERROR SAVING RELINK");
        }).finally(()=>
            setRelinking(false)
        );
    }

    const handleMoreInfoClose = () => {
        setMoreInfoCallSlip(null);
    }
    const handleSetIssuesAndProblemClose = () => {
        let newIssues = itemIssues;
        if  ( !newIssues.some((v)=>v)) {
            newIssues = null;
        }
        toast(messages.problemsReported);
        handleIssuesChange(problemCallSlip, newIssues, itemNotes);
        setProblemCallSlip(null);
    };

    const handleProblemClose = (issues) => {
        setProblemCallSlip(null);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const extractAllItemLocations = (callSlips) => {
        if ( !callSlips ) return [];
        let list = callSlips.filter((cs)=>{
            return (
                (!filters.locationFilter || filters.locationFilter === "all" || cs.callSlipLocation === filters.locationFilter ) &&
                ( !filters.statusFilter || filters.statusFilter === "all" || cs.status === filters.statusFilter ) &&
                ( !filters.problemFilter ||
                    filters.problemFilter === "all" ||
                    (filters.problemFilter === "none" && !cs.callSlipProblem) ||
                    (filters.problemFilter === "only" && cs.callSlipProblem) ||
                    (cs.callSlipProblem && cs.callSlipProblem.value === filters.problemFilter )
                )
            );
        });
        return [...new Set(list.map((cs)=>cs.itemPermLocation))];
    };

    const sortedAndFilteredCallslips = () => {
       return sortedAndFilteredCallslipsProto(callSlips,order,orderBy,filters)
    };

    const [filters, setFilters] = React.useState({
        problemFilter:"all",
        statusFilter: "all",
        locationFilter: "all",
        itemLocationFilter: "all"
    });

    const handleProblemClick = (event, callSlip) => {
        if ( callSlip.status === "New"){
            toast(messages.problemOnNew);
            return;
        }
        setItemIssues((callSlip.callSlipFillProblems && callSlip.callSlipFillProblems.slice())||[]);
        setItemNotes(callSlip.notes || "")
        setProblemCallSlip(callSlip);
        setReportProblemSaveDisable(true);
    };

    const handleFilterSet = (key, value) => {
        const newFilters = {...filters, [key]:value};
        if ( key === "locationFilter" && value !== "all" ) {
            newFilters["itemLocationFilter"] = "all";
        }
        setFilters(newFilters);
        setCallSlipIndex(0);
    };

    const handleSortByChanged = (sortBy ) => {
        setOrderBy(sortBy);
    };

    let sortedCallSlips = sortedAndFilteredCallslips();
    useEffect(()=>{
        currentTab === 0 && callSlipIndex > 0 &&
        handleScrollToItem(sortedCallSlips[callSlipIndex]);
    },[currentTab,callSlipIndex,sortedCallSlips]);

    if ( callSlipsSubmitted ) {
        let newCount = 0;
        let fosCount = 0;
        callSlips.forEach((cs)=> {
                if (cs.status === "New") newCount++;
                if ("FOS_2x".includes(cs.status)) fosCount++;
            }
        );
        return <Grid container alignContent={"center"}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
                <div className={classes.bottomPadding}>
                    <h1>Thank you for submitting your Call Slips!</h1>
                </div>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
                <List>
                    <KeyValue name={"Start Time"} value={startTime.toLocaleString()}  />
                    <KeyValue name={"End Time"} value={endTime.toLocaleString()}  />
                    <KeyValue name={"Status Totals"}  value={<><Typography component={"span"} className={classes.newText} >New: {newCount}</Typography>&nbsp;/&nbsp;
                    <Typography component={"span"} className={classes.fosText} >FOS: {fosCount}</Typography>&nbsp;/&nbsp;
                    <Typography component={"span"} className={classes.nos1Text} >NOS: {callSlips.length - newCount - fosCount}</Typography></>} />
                    <ListItem>
                        <Button color={"primary"}
                                startIcon={<PowerSettingsNewIcon/>}
                                variant={"contained"}
                                onClick={apiLogoutUser}>
                            Log out
                        </Button>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    }

    const handleCallSlipsSubmitted = () => {
        setShowSubmitConfirmDialog(true);
    };

    const handleCallSlipsSubmittedConfirmed = () => {
        return apiSaveCallSlipBatch(callSlipBatch, callSlips.map((cs)=>{ return { id: cs.id, status: cs.status, callSlipFillProblems: cs.callSlipFillProblems, notes: cs.notes }})).then( (callSlipBatch)=> {
            setEndTime(parseISOLocal(callSlipBatch.endTime));
            setStartTime(parseISOLocal(callSlipBatch.startTime));
            setCallSlipsSubmitted(true);
        }).catch( (e)=> {
            toast(messages.saveBatchError);
            setShowSubmitConfirmDialog(false);
        });
    };

    const handleItemIssue = (problem, value ) => {
        let newItemIssues;
        if ( value ) {
            newItemIssues = [problem];
        } else {
            newItemIssues = [];
        }
        setItemIssues(newItemIssues);
        setReportProblemSaveDisable(false);
    };

    const handleItemNotes = (e) => {
        setItemNotes(e.target.value);
        setReportProblemSaveDisable(false);
    };


    let newCount = 0;
    let fosCount = 0;
    if ( callSlips ) {
        callSlips.forEach((cs)=> {
                if (cs.status === "New") newCount++;
                if ("FOS_2x".includes(cs.status)) fosCount++;
            }
        )
    }

    return (
      <div className={classes.root}>
              {showSubmitConfirmDialog &&
              <Dialog open={showSubmitConfirmDialog} classes={{ paper: classes.dialogPaper }} onClose={()=>setShowSubmitConfirmDialog(false)} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">Confirm Submit Batch</DialogTitle>
                  <DialogContent>
                      <List>
                          <ListItem>
                              <Typography component={"span"} className={classes.newText} >New: {newCount}</Typography>&nbsp;/&nbsp;
                              <Typography component={"span"} className={classes.fosText} >FOS: {fosCount}</Typography>&nbsp;/&nbsp;
                              <Typography component={"span"} className={classes.nos1Text} >NOS: {callSlips.length - newCount - fosCount}</Typography>
                          </ListItem>
                          <ListItem>
                              <DialogButton name={"Submit Batch"} disabled={submitDisable} setDisabledState={setSubmitDisable} icon={<NotificationImportantIcon />} label={"Submit Batch"} onClick={()=>handleCallSlipsSubmittedConfirmed()} />
                              <DialogButton name={"Cancel"} disabled={submitDisable} setDisabledState={setSubmitDisable} color="secondary" icon={<CancelIcon />} label={"Cancel"} onClick={()=>setShowSubmitConfirmDialog(false)} />
                          </ListItem>
                      </List>
                  </DialogContent>
              </Dialog>
              }
              {problemCallSlip &&
              <Dialog open={problemCallSlip} classes={{ paper: classes.dialogPaper }} onClose={()=>handleProblemClose()} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">Report Problem</DialogTitle>
                  <DialogContent>
                      <List>
                          {callSlipFillProblems.filter((v)=>!v.secondSearch || problemCallSlip.incomingStatus === "NOS").map((v, ix)=> <><FormControlLabel
                              control={<Checkbox name={v.name}
                                                 checked={itemIssues.some((issue)=>issue.id === v.id )}
                                                 onChange={(e)=>handleItemIssue(v, e.target.checked)} />}
                              label={v.name}
                          /><br /></>)}
                          <TextField
                              id='itemNotes'
                              label="Notes"
                              multiline
                              name="itemNotes"
                              value={itemNotes || ''}
                              margin="normal"
                              fullWidth={true}
                              variant="outlined"
                              inputProps={{ maxLength: 250 }}
                              onChange={handleItemNotes}
                          />
                          <ListItem>
                              <DialogButton disabled={reportProblemSaveDisable} name={"Save"} icon={<NotificationImportantIcon />} label={"Save"} onClick={()=>handleSetIssuesAndProblemClose()} />
                              <DialogButton name={"Cancel"} color="secondary" icon={<CancelIcon />} label={"Cancel"} onClick={()=>handleProblemClose()} />
                          </ListItem>
                      </List>
                  </DialogContent>
              </Dialog>
              }
              {moreInfoCallSlip &&
              <Dialog open={moreInfoCallSlip} classes={{ paper: classes.dialogPaper }} onClose={handleMoreInfoClose} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">More Information</DialogTitle>
                  <DialogContent>
                      <List>
                          <KeyValue name={"Route To"} value={moreInfoCallSlip.routeTo}/>
                          <KeyValue name={"Call Number"} value={moreInfoCallSlip.callNumber} />
                          <KeyValue name={"Title"} value={moreInfoCallSlip.title} />
                          <KeyValue name={"Author"} value={moreInfoCallSlip.author} />
                          <KeyValue name={"Item Barcode"} value={moreInfoCallSlip.itemBarcode}/>
                          <KeyValue name={"Patron Barcode"} value={moreInfoCallSlip.patronBarcode}/>
                          <KeyValue name={"Enumeration"} value={moreInfoCallSlip.enumeration} />
                          <KeyValue name={"Patron Request Date"} value={moreInfoCallSlip.patronRequestDate} />
                          <KeyValue name={"Patron Comment"} value={moreInfoCallSlip.patronComment} />
                          {moreInfoCallSlip.problem && <KeyValue name={"Problem"} value={moreInfoCallSlip.problem.name} />}
                          <ListItem>
                              <DialogButton name={"Close"} icon={<DoneIcon />} label={"Close"} onClick={handleMoreInfoClose} />
                          </ListItem>
                      </List>
                  </DialogContent>
              </Dialog>
              }
              {relinkCallSlip &&
              <>
                  <Dialog open={relinkCallSlip} classes={{ paper: classes.dialogPaper }} onClose={handleRelinkBarcodeClose} aria-labelledby="form-dialog-title">
                      <DialogTitle id="form-dialog-title">Relink to Another Item</DialogTitle>
                      <DialogContent>
                           <List>
                               <KeyValue name={"Call Number"} value={relinkCallSlip.callNumber} />
                               <KeyValue name={"Title"} value={relinkCallSlip.title} />
                               <KeyValue name={"Item Barcode"} value={relinkCallSlip.itemBarcode} />
                               <KeyValue name={"Barcode On Book"}
                                         value={
                                                <Input autoFocus
                                                       value={relinkBarcode}
                                                       inputProps={{"aria-label":"bar-code-edit-box"}}
                                                       onChange={
                                                           (e)=>setRelinkBarcode(
                                                               e.target.value
                                                           )
                                                       }
                                                >
                                                </Input>} />
                               <ListItem>
                                   <DialogButton name={"Relink"} disabled={relinking} icon={<DoneIcon />} label={"Relink"} onClick={handleSaveRelinkClose} />
                                   <DialogButton name={"Cancel"} disabled={relinking} color="secondary" icon={<CancelIcon />} label={"Close"} onClick={handleRelinkBarcodeClose} />
                               </ListItem>
                               <ListItem>
                                   {relinking && <CircularProgress />}
                                   {relinkError && <Typography>{relinkError}</Typography>}
                               </ListItem>
                           </List>
                      </DialogContent>
                  </Dialog>
              </>
              }
              {reloadCacheConfirmOpen && <ConfirmDialog
                  open={reloadCacheConfirmOpen}
                  handleClose={()=>setReloadCacheConfirmOpen(false)}
                  handleConfirm={()=>{
                      restoreCache(callSlipBatch, callSlipBatch.callSlips );
                      setReloadCacheConfirmOpen(false);
                  }}
                  classes={classes}
                  confirmTitle={"Reload Device Cache?"}
              >
                  <Typography>
                      Some cached responses for these items were found on this devise.<br />
                      This may be because the device was offline and was unable to save responses.<br /><br />

                      Do you want to apply the values from the cache?
                  </Typography>
              </ConfirmDialog>

              }
              <Tabs value={currentTab} onChange={handleTabChange} aria-label="callslip tabs">
                  <Tab label="List Call Slip View"/>
                  <Tab label="Single Call Slip View"/>
              </Tabs>
              <EnhancedTableToolbar numSelected={sortedAndFilteredCallslips().length}
                                    onFilterSet={handleFilterSet}
                                    onSortByChanged={handleSortByChanged}
                                    sortBy={orderBy==="sortTitle"?"title":orderBy}
                                    showSortBy={true}
                                    showNOSOptions={false}
                                    callSlipLocations={callSlipLocations}
                                    itemLocations={extractAllItemLocations(callSlips)}
                                    filters={filters}/>
              {(sortedCallSlips.length === 0 && (callSlips === null) &&
                  (<Card><CardContent><Typography>Loading...</Typography></CardContent></Card>))
              || (sortedCallSlips.length === 0 &&
                  (<Card><CardContent><Typography>{callSlipLoadError || 'No Call Slips based on Your Selection'}</Typography></CardContent></Card>))
              }
              {currentTab === 0 &&
              sortedCallSlips.map((callSlip, ix)=>
                    <CallSlip key={callSlip.id} callSlip={callSlip} classes={classes} statusMap={statusMap}
                              onUpdateCallSlipStatus={(callSlip, status)=> handleStatusChange(callSlip, status)}
                              onMoreInformation={()=>{
                                  setCallSlipIndex(ix);
                                  setCurrentTab(1);
                              }}
                              onProblem={(event)=>{
                                 handleProblemClick(event,callSlip)
                              }}
                              onRelink={()=>{
                                setRelinkCallSlip(callSlip);
                                setRelinkBarcode("");
                                setRelinkError("");
                              }}
                              online={online}
                              useButtons={false}
                              callSlipFillProblems={callSlipFillProblems}
                              statusOptions={callSlip.incomingStatus === "New" ? firstSearchStatusOptions : secondSearchStatusOptions}
                    />
                  )
              }
              {currentTab === 1 && sortedCallSlips.length > callSlipIndex &&
                  <div>
                      <SingleViewCallSlip callSlip={sortedCallSlips[callSlipIndex]} classes={classes}
                                onUpdateCallSlipStatus={(callSlip, status)=> handleStatusChange(sortedCallSlips[callSlipIndex], status)}
                                onMoreInformation={()=>setMoreInfoCallSlip(sortedCallSlips[callSlipIndex])}
                                onProblem={(event)=>{
                                  let callSlip = sortedCallSlips[callSlipIndex];
                                  handleProblemClick(event,callSlip)
                                }}
                                onRelink={()=>{
                                    setRelinkCallSlip(sortedCallSlips[callSlipIndex]);
                                    setRelinkBarcode("");
                                    setRelinkError("");
                                }}
                                useButtons={true}
                                online={online}
                                callSlips={sortedCallSlips}
                                callSlipIndex={callSlipIndex}
                                setCallSlipIndex={setCallSlipIndex}
                                callSlipFillProblems={callSlipFillProblems}
                                statusOptions={sortedCallSlips[callSlipIndex].incomingStatus === "New" ? firstSearchStatusOptions : secondSearchStatusOptions}
                                statusMap={statusMap}
                                setTab={setCurrentTab}
                      />
                  </div>

              }
              <div className={classes.bottomPadding}></div>
              {callSlips && callSlips.length > 0 && <WorkProgress classes={classes} callSlips={callSlips} loggedInUser={loggedInUser}
                                                                  startTime={startTime}
                                                                  onCallSlipsSubmitted={handleCallSlipsSubmitted}
                                                                  newCount={newCount} fosCount={fosCount}
                                                                  toolBar={true}/>}
      </div>
  );
}