import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {List, ListItem} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ImageUploader from "react-images-upload";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import Typography from "@material-ui/core/Typography";

export const EditUserDialog = ({user, classes, open, handleSave, handleDelete, handleClose, handleChange, libraries, handleAvatarChanged}) => {
    return(
        <Dialog role={(open && "presentation") || "none" } open={open} onClose={handleClose} aria-labelledby="edit-user-dialog" fullWidth={true}>
            <DialogTitle id="edit-user-dialog">{(user && user.isNew) ? "Add":"Edit"} User & Role</DialogTitle>
            <DialogContent>
                <List>
                    <ListItem>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField
                                    id={`firstName-${user.id}`}
                                    className={classes.dialogControl}
                                    label="First Name"
                                    name="firstName"
                                    value={(user && user.firstName) || ''}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id={`lastName-${user.id}`}
                                    className={classes.dialogControl}
                                    label="Last Name"
                                    name="lastName"
                                    value={(user && user.lastName) || ''}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container>
                            <Grid item xs={6}>
                                <FormControl variant="outlined"
                                             className={classes.dialogControl}
                                             margin="normal">
                                    <InputLabel className="bg-white" htmlFor="libraries-select">CallSlip Print Group</InputLabel>
                                    <Select
                                        variant="outlined"
                                        id={"libraries-select"}
                                        placeholder={"Libraries"}
                                        label={"Libraries"}
                                        name={"libraries"}
                                        multiple
                                        value={user.libraries && (user.libraries.map((l)=>libraries.filter((library)=>library.id === l.id)[0]) || [])}
                                        onChange={handleChange}
                                        renderValue={(selected) => selected.map((library)=>library.name).join(', ')}
                                    >
                                        {libraries && libraries.map((library) => {
                                                return (
                                                    <MenuItem key={library.name} value={library}>
                                                        <Checkbox checked={user.libraries && user.libraries.some((l)=>l.id === library.id)} />
                                                        <ListItemText id={`${user.netId}-library-${library.voyagerId}`} primary={library.name} />
                                                    </MenuItem>
                                                )
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.dialogControl}
                                    label="Net-id"
                                    id={`netId-${user.netId}`}
                                    value={user.netId}
                                    margin="normal"
                                    name={"netId"}
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container>
                            <Grid item xs={4} >
                                <List>
                                    <ListItem>
                                        <FormGroup id="outlined-margin-normal"
                                                   label="Role"
                                        >
                                            <FormControlLabel
                                                control={<Checkbox onChange={handleChange} checked={user.assign} name="assign" color="primary" />}
                                                label="Assign Callslip"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={handleChange}
                                                        name="retrieve"
                                                        color="primary"
                                                        checked={user.retrieve}
                                                    />
                                                }
                                                label="Retrieve"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={handleChange}
                                                        name="admin"
                                                        color="primary"
                                                        checked={user.admin}
                                                    />
                                                }
                                                label="Admin"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={handleChange}
                                                        name="enabled"
                                                        color="primary"
                                                        checked={user.enabled}
                                                    />
                                                }
                                                label="Active User"
                                            />
                                        </FormGroup>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <label>
                                    <Typography style={{'display': 'none'}}>
                                        Image Uploader
                                    </Typography>
                                    <ImageUploader
                                        id={`image-${user.id}`}
                                        defaultImages={user.avatar?[user.avatar]:[]}
                                        buttonText='Choose Picture'
                                        onChange={handleAvatarChanged}
                                        imgExtension={['.jpg', '.png', '.gif']}
                                        maxFileSize={1024 * 1024} //1MB
                                        singleImage={true}
                                        withIcon={false}
                                        withLabel={false}
                                        withPreview={true}
                                        fileContainerStyle={{width:"350px"}}
                                    />
                                </label>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button id={`delete-${user.id}`} onClick={handleDelete} color="primary"  >
                    Delete
                </Button>
                <div style={{flex: '1 0 0'}} />
                <Button id={`save-${user.id}`} onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
};