import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";

export const KeyValue = ({name, value,  singleLine, className}) => {
    if ( singleLine ) {
        return <Grid container><Grid item sm={3} className={"text-label"}>{name}:</Grid><Grid item sm={9}>{value}</Grid></Grid>
    } else {
        return (<ListItem>
            <ListItemText
                primary={name}
                secondary={value}
            />
        </ListItem>);
    }
};