import React, {useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import UserIcon from '@material-ui/icons/AccountCircle';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from "@material-ui/core/Grid";
import {
    apiDeleteUser,
    apiFetchAllUsers,
    apiFetchAvatarDataUrl,
    apiFetchLibraries,
    apiSaveUser,
    avatarImagePath
} from "./functions/APIClient";
import InputLabel from "@material-ui/core/InputLabel";
import {extractLibraryDisplayName, extractUserDisplayName} from "./functions/UiFunctions";
import {EnhancedTableHead} from "./components/ui-components/EnhancedTableHead";
import {getComparator, stableSort} from "./functions/SortFilterOrder";
import {EditUserDialog} from "./components/admin-user-role/EditUserDialog";
import {useStyles} from "./styles/AdminUserRoleStyle";
import {messages} from "./toast-messages/AdminUserRoleMessages";

const headCells = [
    {id: 'lastName', numeric: false, disablePadding: false, label: 'Name', sortable: true},
    {id: 'libraries', numeric: false, disablePadding: false, label: 'CallSlip Print Group', sortable: false},
    {id: 'enabled', numeric: false, disablePadding: true, label: 'User Status', sortable: true},
    {id: 'assign', numeric: false, disablePadding: true, label: 'Assign', sortable: true},
    {id: 'retrieve', numeric: false, disablePadding: true, label: 'Retrieve', sortable: true},
    {id: 'edit', numeric: false, disablePadding: false, label: 'Edit', sortable: false},
];

export function AdminUserRole({onUpdateLoggedInUser, loggedInUser, toast}) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('avatar');
    const [page, setPage] = React.useState(0);
    const [usersPerPage, setUsersPerPage] = React.useState(5);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [activeOnly, setActiveOnly] = React.useState(true);
    const [selectedUserIndex, setSelectedUserIndex] = React.useState(0)
    const [users, setUsers] = React.useState([]);
    const [filter, setFilter] = React.useState("");
    const [libraries, setLibraries] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState({
        name: "",
        netid: "",
        avatar: "",
    });

    useEffect(() => {
        apiFetchAllUsers().then(
            (users) => setUsers(users)
        )
    }, []);
    useEffect(() => {
        apiFetchLibraries().then(
            (libraries) => setLibraries(libraries)
        )
    }, []);


    const filteredUsers = () => {
        if (!users) return;

        return users.filter((u) => {
            return (u.enabled || !activeOnly) && u.libraries.some((l)=>{ return loggedInUser.libraries.some((ll)=> {return l.id === ll.id})}) &&
                (!filter || [u.firstName, u.lastName, u.netId].join(" ").toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        });
    };

    const sortedUsers = () => {
        return stableSort(userList, getComparator(order, orderBy))
    };

    const handleChangeUserInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newUser = Object.assign({}, selectedUser);
        newUser[event.target.name] = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setSelectedUser(newUser)
    };

    const handleAvatarChanged = (avatars, avatarUrls) => {
        let newUser = Object.assign({}, selectedUser);
        newUser.avatar = avatarUrls[0];
        setSelectedUser(newUser);
    };

    const handleSave = () => {
        // make the API call to save the user.
        let newUsers = [...users];
        apiSaveUser(selectedUser).then((newUser) => {
            selectedUser.isNew = false;
            if (selectedUser.id === loggedInUser.id) onUpdateLoggedInUser();
            if (selectedUserIndex === -1) {
                newUsers.unshift(newUser);
            } else {
                newUsers = newUsers.map((user) => {
                    if (user.id === newUser.id) {
                        return newUser;
                    }
                    return user;
                });
            }
            setUsers(newUsers);
            setDialogOpen(false);
            toast(messages.saveUserSuccess);
        }).catch((error) => {
            error.response.then(
                (error_json) => {
                    toast({message: error_json.message, severity: 'error'});
                }
            ).catch((error) => {
                toast(messages.saveUserFailure);
            });
        });
    };

    const handleDelete = () => {
        apiDeleteUser(selectedUser).then((result) => {
            let newUsers = [...users].filter(user => user.id !== selectedUser.id);
            setUsers(newUsers);
            setDialogOpen(false);
            toast(messages.deleteUserSuccess)
        }).catch((error) => {
            error.response.then(
                (error_json) => {
                    toast({message: error_json.message, severity: 'error'});
                }
            ).catch((error) => {
                toast(messages.deleteUserFailure);
            });
        })
    }

    const handleClose = () => {
        setDialogOpen(false)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleEditUser = (event, user, index) => {
        event.stopPropagation();
        setSelectedUserIndex(index);
        if (user.avatar) {
            setSelectedUser({...user});
            setDialogOpen(true);
        } else {
            apiFetchAvatarDataUrl(user).then((dataUrl) => {
                user = {...user, avatar: dataUrl};
                setSelectedUser(user);
                setDialogOpen(true);
            }).catch((e) => {
                toast(messages.errorLoadingAvatar);
                console.error(e);
            })
        }
    };

    const handleNewUser = (event, user, index) => {
        event.stopPropagation();
        setSelectedUser({name: "", isNew: true, libraries: []});
        setSelectedUserIndex(-1);
        setDialogOpen(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeUsersPerPage = (event) => {
        setUsersPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const userList = filteredUsers();
    const emptyUsers = usersPerPage - Math.min(usersPerPage, userList.length - page * usersPerPage);

    return (
        <div className={classes.root}>
            <EditUserDialog
                handleAvatarChanged={handleAvatarChanged}
                libraries={libraries}
                user={selectedUser}
                open={dialogOpen}
                classes={classes}
                handleChange={handleChangeUserInfo}
                handleSave={handleSave}
                handleDelete={handleDelete}
                handleClose={handleClose}/>
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid>
                        <TextField id={"userSearchAdmin"}
                                   className={classes.textField}
                                   onChange={
                                       (e) => {
                                           setFilter(e.target.value);
                                           setPage(0)
                                       }
                                   }
                                   label={"Search by Name/NetID"}
                                   InputLabelProps={{
                                       "aria-label": "userSearchAdmin"
                                   }}
                        >
                        </TextField>
                    </Grid>
                    <Grid>
                        <InputLabel className={classes.activeOnlyButton}><Checkbox id={"activeOnlyButton"}
                                                                                   checked={activeOnly}
                                                                                   onChange={(e) => setActiveOnly(e.target.checked)}/> Active
                            User Only</InputLabel>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            userCount={userList.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {sortedUsers()
                                .slice(page * usersPerPage, page * usersPerPage + usersPerPage)
                                .map((user, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={user.id}
                                            className={`user-row-${user.netId}`}
                                        >
                                            <TableCell className={classes.userDisplay}>
                                                <Avatar alt={`${extractUserDisplayName(user)} Avatar`} src={avatarImagePath(user)}
                                                        className={classes.avatar}/>
                                                <Typography
                                                    className={classes.userNameText}>{extractUserDisplayName(user)} </Typography>
                                            </TableCell>
                                            <TableCell className={classes.libraryNameText}>
                                                <Typography
                                                    className={classes.libraryNameText}>{extractLibraryDisplayName(user)} </Typography>
                                            </TableCell>
                                            <TableCell padding="checkbox">
                                                <UserIcon color={user.enabled ? "primary" : "disabled"}
                                                          titleAccess={user.enabled ? "Active User" : "Inactive User"}/>
                                            </TableCell>
                                            <TableCell padding="checkbox">
                                                {(user.assign && <CheckBoxIcon titleAccess="Can Assign"/>) ||
                                                <CheckBoxOutlineBlankIcon titleAccess="Can Not Assign"/>}
                                            </TableCell>
                                            <TableCell padding="checkbox">
                                                {(user.retrieve && <CheckBoxIcon titleAccess="Can Retrieve"/>) ||
                                                <CheckBoxOutlineBlankIcon titleAccess="Can Not Retrieve"/>}
                                            </TableCell>
                                            <TableCell id={labelId} scope="col" padding="none">
                                                <IconButton id={`edit-${user.netId}`} aria-label={`Edit ${user.netId}`}
                                                            onClick={(event) => handleEditUser(event, user, index + (page * usersPerPage))}>
                                                    <EditIcon titleAccess={`Edit ${user.netId}`}/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyUsers > 0 && (
                                <TableRow style={{height: 73 * emptyUsers}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container>
                    <Grid item xs={6} className={classes.buttonArea}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            startIcon={<AddBoxIcon/>}
                            onClick={handleNewUser}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10]}
                            component="div"
                            count={userList.length}
                            rowsPerPage={usersPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeUsersPerPage}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}