import React, {Fragment, useEffect, useState} from 'react';
import ReactDOM from 'react-dom'
import './App.css';
import {apiFetchLoggedInUser} from "./functions/APIClient";
import {apiLogoutUser} from "./functions/APIClient";
import {AdminUserRole} from "./AdminUserRole";
import {AssignCallSlip} from "./AssignCallSlip";
import {MyCallSlip} from "./MyCallSlip";
import {AppBar, Container, IconButton, Toolbar, Typography} from '@material-ui/core'
import {appTheme} from './styles/materialThemeOverride'
import AccountCircle from '@material-ui/icons/AccountCircle'
import {makeStyles, withTheme} from '@material-ui/core/styles'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import CancelIcon from "@material-ui/icons/Cancel";
import {useSnackbar} from "notistack";
import {extractUserDisplayName} from "./functions/UiFunctions";
import {DialogButton} from "./components/ui-components/DialogButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {OurSnackbar} from "./components/ui-components/SnackBar";
import Button from "@material-ui/core/Button";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import Route from "react-router-dom/es/Route";
import {MainTabs} from "./components/ui-components/MainTabs";
import DisplayStackGuide from "./components/ui-components/DisplayStackGuide";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
    },
    offset: theme.mixins.toolbar,
    breadcrumbs: {
        marginTop: 20,
        marginBottom: 20
    },
    fullWidth: {
        marginTop: 25,
        marginBottom: 10,
        height: 70,
        width: '100%'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 18,
        height: 18,
        marginBottom: -3,
        borderBottom: 0
    },
    online: {
        color: "inherit"
    },
    notonline: {
        color: "#222222"
    }
}));
let toastCount = 0;

const initialRoutes = {assign:["/assign"], callslip:["/callslips"], admin:["/admin"]};

function App() {
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [online, setOnline] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const classes = useStyles(appTheme);
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [messageToUse, setMessageToUse] = React.useState(null);
    const {closeSnackbar} = useSnackbar();
    const [routes, setRoutes] = React.useState(initialRoutes);

    const toastActions = (key) => (
        <Fragment>
            <Button onClick={() => {
                closeSnackbar(key)
            }}>
                close
            </Button>
        </Fragment>
    );
    const setUpToastMessage = (message) => {
        let newMessage = Object.assign({}, message);
        newMessage["key"] = toastCount;
        toastCount++;
        setMessageToUse(newMessage)
    };

    const fetchLoggedInUser = async () => {
        try {
            const loggedInUser = await apiFetchLoggedInUser();
            if (!loggedInUser) {
                throw new Error("Not Logged In");
            } else {
                let newRoutes = initialRoutes;
                if (loggedInUser.admin) {
                    newRoutes.admin.push("/");
                } else if (loggedInUser.assign) {
                    newRoutes.assign.push("/");
                } else {
                    newRoutes.callslip.push("/");
                }
                setRoutes(newRoutes);
                setLoggedInUser(loggedInUser);
            }
        } catch (e) {
            if (e.status === 403) {
                window.location.href = process.env.REACT_APP_API_SERVER + "/api/entry-point"
            }
        }
    };

    useEffect(() => {
        fetchLoggedInUser();
    }, []);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            apiFetchLoggedInUser().then((u) => {
                setForbidden(false);
                setOnline(u && true);
            }).catch((e) => {
                setOnline(false);
                if (e.status === 403) {
                    setForbidden(true);
                }
            })
        }, 10000);
        return () => clearInterval(timerInterval);
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setUserMenuOpen(!userMenuOpen);
    };

    if (!loggedInUser) {
        return (
            <main>
                <Card>
                    <CardContent>
                        <Typography component={"h1"} role={"heading"} variant={"h6"} >Loading...</Typography>
                    </CardContent>
                </Card>
            </main>
        )
    }
    return (
        <main>
            <div className={classes.root}>
                <OurSnackbar messageToUse={messageToUse} action={toastActions}>
                    <Container maxWidth="lg" disableGutters={true}>
                        <Dialog open={forbidden} classes={{paper: classes.dialogPaper}}
                                onClose={() => setForbidden(false)}
                                aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Session Timeout</DialogTitle>
                            <DialogContent>
                                <List>
                                    <ListItem>
                                        Your Session Has Expired
                                    </ListItem>
                                    <ListItem>
                                        <DialogButton name={"Reload"} icon={<NotificationImportantIcon/>}
                                                      label={"Reload"}
                                                      onClick={() => window.location.reload()}/>
                                        <DialogButton name={"Cancel"} color="secondary" icon={<CancelIcon/>}
                                                      label={"Cancel"} onClick={() => setForbidden(false)}/>
                                    </ListItem>
                                </List>
                            </DialogContent>
                        </Dialog>
                        <nav>
                            <AppBar position="sticky">
                                <Toolbar>
                                    <Typography variant="h6"
                                                component="h1"
                                                role={"heading"}
                                                className={classes.title}>Yale University Library Paging
                                        Service</Typography>
                                    <IconButton onClick={handleMenuOpen}
                                                edge="end"
                                                aria-label="account of current user"
                                                aria-haspopup="true"
                                                color="inherit"
                                    >
                                        {loggedInUser && extractUserDisplayName(loggedInUser)}&nbsp;&nbsp;
                                        <AccountCircle className={online ? classes.online : classes.notonline}/>
                                    </IconButton>
                                    <Menu open={userMenuOpen} onClose={() => {
                                        setAnchorEl(null);
                                        setUserMenuOpen(false);
                                    }} anchorEl={anchorEl} elevation={0}>
                                        <MenuItem onClick={apiLogoutUser}>Logout</MenuItem>
                                    </Menu>
                                    {loggedInUser && (loggedInUser.libraries.some((l)=>l.name === "SML")) &&
                                         <DisplayStackGuide/>
                                    }
                                </Toolbar>
                            </AppBar>
                        </nav>
                        <div>
                            <Router>
                                {loggedInUser && (loggedInUser.assign || loggedInUser.admin) &&
                                    <MainTabs loggedInUser={loggedInUser}/>
                                }
                                <Switch>
                                    <Route exact path={routes.admin} >
                                        {(loggedInUser && loggedInUser.admin) &&
                                            <AdminUserRole loggedInUser={loggedInUser} onUpdateLoggedInUser={fetchLoggedInUser}
                                                           online={online} toast={setUpToastMessage}/>
                                        }
                                    </Route>
                                    <Route exact path={routes.assign}>
                                        {(loggedInUser && loggedInUser.assign) &&
                                            <AssignCallSlip loggedInUser={loggedInUser} online={online} toast={setUpToastMessage}/>
                                        }
                                    </Route>
                                    <Route exact path={routes.callslip}>
                                        {(loggedInUser && loggedInUser.retrieve) &&
                                            <MyCallSlip loggedInUser={loggedInUser} online={online} toast={setUpToastMessage}/>
                                        }
                                    </Route>
                                </Switch>
                            </Router>
                        </div>
                    </Container>
                </OurSnackbar>
            </div>
        </main>
    );
}

if (process.env.NODE_ENV !== 'production') {
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000, {}, undefined);
}
export default withTheme(App);