import React from 'react';
import AllPagesPDFViewer from "./AllPages";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from "@material-ui/icons/Close"
import LiveHelpSharpIcon from "@material-ui/icons/LiveHelpSharp";
import Tooltip from "@material-ui/core/Tooltip";

class DisplayStackGuide extends React.Component {

    state = {
        open: false,
        scroll: 'paper',
    };

    handleClickOpen = scroll => () => {
        this.setState({ open: true, scroll });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <Tooltip color="inherit" title="Stack Guide">
                    <Button aria-label="StackGuide" onClick={this.handleClickOpen('paper')}>
                        <LiveHelpSharpIcon/>
                    </Button>
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    scroll={this.state.scroll}
                    aria-label="Stack Guide Dialog"
                >
                    <DialogContent>
                        <AllPagesPDFViewer />
                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <Button color={"primary"} onClick={this.handleClose} startIcon={<CloseIcon/>} variant={"contained"} >Close</Button>
                </Dialog>
            </div>
        );
    }
}

export default DisplayStackGuide;