import {Tab, Tabs} from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export const MainTabs = ({loggedInUser}) =>
{
    const history = useHistory();
    const location = useLocation();
    const handleTabChange = (event, newValue) => {
        history.push(`/${newValue}`);
    }
    const currentTab = location.pathname.substring(1);
    return (
    <Tabs value={currentTab} onChange={handleTabChange} aria-label="Main Tabs">
        {(loggedInUser && loggedInUser.admin) &&
        <Tab label="Admin User/Role" value={"admin"}/>}
        {(loggedInUser && loggedInUser.assign) &&
        <Tab label="Assign Call Slips" value={"assign"}/>}
        {(loggedInUser && loggedInUser.retrieve) &&
        <Tab label="My Call Slips" value={"callslips"}/>}
    </Tabs>
    )
}