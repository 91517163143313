export const messages = {
    "saveAssignSuccess": {
        "message": "Call slips have been successfully assigned!",
        "severity": "success"
    },
    "saveAssignFailure": {
        "message": "Error assigning Call slips! Try Refreshing.",
        "severity": "error"
    },
    "selectWithoutUser": {
        "message": "Need to select a User first!",
        "severity": "error"
    },
};