import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import yellow from '@material-ui/core/colors/yellow'



export const appTheme = responsiveFontSizes( 
  createMuiTheme({
    palette: {
      primary: {
          light: '#002884', 
          main: '#286dc0',
          dark: '#00356b',
          contrastText: '#fff',
      },
      secondary: {
          light: '#dddddd',
          main: '#4a4a4a',
          dark: '#222222',
          contrastText: '#fff',
      },
      warning: {
          light: yellow[500],
          main: yellow[700],
          dark: yellow[900],
          contrastText: '#fff',
      },
    },}));