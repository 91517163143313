import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DoneIcon from "@material-ui/icons/Done";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {DialogButton} from "./DialogButton";
import {WrapInAppBar} from "./WrapInAppBar";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        formControl: {
            margin: theme.spacing(1),
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        tableCell: {

        },
        nos1: {
            borderColor: "#e57373",
        },
        nos1Text: {
            color: "#e57373",
        },
        nos2: {
            borderColor: "#e57373",
        },
        nos2Text: {
            color: "#e57373",
        },
        nos2Button: {
            background: "#e57373",
        },

        fosText: {
            color: "#4caf50"
        },
        newText: {
            color: "#2196f3"
        },
        floatRight: {
            float: "right"
        },
        submitButton: {
            marginTop: '10px'
        },
        justifyEnd: {
            justifyContent: "flex-end"
        },
        workProgress: {
            background: theme.palette.grey[100]
        },
        circularProgress: {
            marginTop: "10px"
        },
        bottomAppBar: {
            top: 'auto',
            bottom: 0,
        },
        bottomPadding: {
            height: "100px"
        },
        bigFont: {
            fontSize: "100px"
        }
    }),
);

export function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" aria-label={"Progress Bar"} {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export const WorkProgress = ({toolBar = true, adminSubmit = false, callSlips, loggedInUser, onCallSlipsSubmitted, startTime, newCount, fosCount }) => {
    const classes = useStyles();
    return(
        <WrapInAppBar classes={classes} wrap={toolBar}>
            <Grid container className={classes.workProgress}>
                <Grid container item xs={6} >
                    <Grid>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Total Callslips
                                    </TableCell>
                                    <TableCell>
                                        {callSlips.length}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography component={"span"} className={classes.newText} >New: {newCount}</Typography>&nbsp;/&nbsp;
                                        <Typography component={"span"} className={classes.fosText} >FOS: {fosCount}</Typography>&nbsp;/&nbsp;
                                        <Typography component={"span"} className={classes.nos1Text} >NOS: {callSlips.length - newCount - fosCount}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid>
                        <CircularProgressWithLabel className={classes.circularProgress} value={100 * ((callSlips.length - newCount) / callSlips.length)}  variant="static" />
                    </Grid>
                </Grid>
                <Grid container item xs={6} className={classes.justifyEnd}>
                    <Grid>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Start Time
                                    </TableCell>
                                    <TableCell>
                                        {startTime.toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid>
                        <DialogButton name={"Submit Work"}
                                      icon={<DoneIcon />}
                                      label={"Submit Work"}
                                      disabled={newCount === callSlips.length}
                                      onClick={onCallSlipsSubmitted}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </WrapInAppBar>
    )
}