import React, {useState} from "react";
import {Document, Page} from "react-pdf";
import StackGuide from "../../static/stackguide.pdf";

export default function AllPages() {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    function handleLoadError(error) {
        console.log(error.message);
    }

    return (
        <Document file={StackGuide}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={handleLoadError}
        >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1}/>
            ))}
        </Document>
    );
}